import React from 'react';
import { Link } from 'react-router-dom';
import Logout from './Logout';
import { useAuth } from './AuthContext';

const Navigation = () => {
    const { currentUser } = useAuth();
    return (
        <nav>
            {currentUser ? (
                <>
                    <Link to="/" className="button nav-link">Enter Billing</Link>
                    <Link to="/create-task" className="button nav-link">Create Task</Link>
                    <Link to="/show-tasks" className="button nav-link">List Current Stored Tasks</Link>
                    <Logout />
                </>
            ) : (
                <>Enter your Login Credentials Below</>
            )}
        </nav>
    );
};

export default Navigation;
