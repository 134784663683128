// TaskCreationForm.js

import React, { useState } from 'react';
import { firestore } from './firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

const TaskCreationForm = () => {
    const [formData, setFormData] = useState({
        taskGroup: '',
        taskFullName: '',
        taskShortName: '',
        user: '',
        client: '',
        defaultDescription: '',
        defaultHours: 0,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("FormData before submission:", formData); // Debugging line
    
        try {
            const docRef = await addDoc(collection(firestore, 'tasks'), formData);
            console.log("Document written with ID: ", docRef.id); // Debugging line
            alert('Task added successfully');
            // Reset form data...
        } catch (error) {
            console.error("Error adding task: ", error);
            alert('Error adding task');
        }
    };

    return (
        <div className='large-section'>
            <div className='form-section'>
                <form onSubmit={handleSubmit}>
                    <h2>Create New Task</h2>
                    <label>
                        Task Group:
                        <input type="text" name="taskGroup" value={formData.taskGroup} onChange={handleChange} />
                    </label>
                    <label>
                        Task Full Name:
                        <input type="text" name="taskFullName" value={formData.taskFullName} onChange={handleChange} />
                    </label>
                    <label>
                        Task Short Name:
                        <input type="text" name="taskShortName" value={formData.taskShortName} onChange={handleChange} />
                    </label>
                    <label>
                        User:
                        <input type="text" name="user" value={formData.user} onChange={handleChange} />
                    </label>
                    <label>
                        Client:
                        <input type="text" name="client" value={formData.client} onChange={handleChange} />
                    </label>
                    <label>
                        Default Description:
                        <input type="text" name="defaultDescription" value={formData.defaultDescription} onChange={handleChange} />
                    </label>
                    <label>
                        Default Hours:
                        <input type="number" name="defaultHours" value={formData.defaultHours} onChange={handleChange} />
                    </label>
                    <button type="submit">Create Task</button>
                </form>
            </div>
        </div>
    );
};

export default TaskCreationForm;