//EmailForm.js

import React, { useState, useEffect } from 'react';
import { firestore } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import SelectSection from './SelectSection';

const EmailForm = () => {
    const [tasks, setTasks] = useState({});
    const [date, setDate] = useState('');
    const [hours, setHours] = useState('');
    const [description, setDescription] = useState('');
    const [rate, setRate] = useState(400);
    const [timekeeper, setTimekeeper] = useState(847);
    const [subject, setSubject] = useState('');
    const [emailPreview, setEmailPreview] = useState('');

    useEffect(() => {
        const fetchTasks = async () => {
            const querySnapshot = await getDocs(collection(firestore, 'tasks'));
            const tasksData = {};
            querySnapshot.forEach((doc) => {
                const task = doc.data();
                if (!tasksData[task.taskGroup]) {
                    tasksData[task.taskGroup] = {};
                }
                tasksData[task.taskGroup][task.taskShortName] = {
                    fullDescription: task.defaultDescription,
                    defaultHours: task.defaultHours,
                };
            });
            setTasks(tasksData);
        };

        fetchTasks();
    }, []);

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setDate(today);
    }, []);

    useEffect(() => {
        const formattedDate = formatDate(date);
        const previewContent = `To: FIPBILLING@slwip.com\nSubject: ${subject}\n\nINCUR DATE=${formattedDate}\nHOURS=${hours}\nDESCRIPTION=${description}\nRATE=${rate}\nTIMEKEEPER=${timekeeper}`;
        setEmailPreview(previewContent);
    }, [date, hours, description, rate, timekeeper, subject]);

    const handleSelectionChange = (section, selectedTask) => {
        if (selectedTask && tasks[section] && tasks[section][selectedTask]) {
            const { fullDescription, defaultHours } = tasks[section][selectedTask];
            setDescription(fullDescription);
            setHours(defaultHours);
        } else {
            setDescription('');
            setHours('');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const emailBody = `INCUR DATE=${formatDate(date)}\nHOURS=${hours}\nDESCRIPTION=${description}\nRATE=${rate}\nTIMEKEEPER=${timekeeper}`;
        const mailtoLink = `mailto:FIPBILLING@slwip.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
        window.location.href = mailtoLink; // This line should open the email client
    };

    return (
        <div className='large-section'>
            <form onSubmit={handleSubmit} className="form-container">
                {Object.keys(tasks).map((section) => (
                    <SelectSection
                        key={section}
                        title={section}
                        options={Object.keys(tasks[section])}
                        onSelectionChange={handleSelectionChange}
                    />
                ))}
                <div className="form-section">
                    <label>
                        Incur Date:
                        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                    </label>
                    <label>
                        Matter# (Subject):
                        <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </label>
                    <label>
                        Description:
                        <input 
                            type="text" 
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </label>
                    <label>
                        Hours:
                        <input type="number" value={hours} onChange={(e) => setHours(e.target.value)} />
                    </label>
                    <label>
                        Hourly Rate:
                        <input type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
                    </label>
                    <label>
                        Timekeeper:
                        <input type="number" value={timekeeper} onChange={(e) => setTimekeeper(e.target.value)} />
                    </label>
                    <button type="submit">Generate Email</button>
                </div>
            </form>
            <div>
                <h3>Email Preview:</h3>
                <pre>{emailPreview}</pre>
            </div>
            
        </div>
    );
};

export default EmailForm;

const formatDate = (dateString) => {
    const [yyyy, dd, mm] = dateString.split('-');
    return `${dd}/${mm}/${yyyy}`;
};
