// src/SelectSection.js
import React from 'react';

const SelectSection = ({ title, options, onSelectionChange }) => {
    return (
        <div className="description-section">
            <h3>{title}</h3>
            <select onChange={(e) => onSelectionChange(title, e.target.value)}>
                <option value="">Select {title} Task</option>
                {options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
        </div>
    );
};

export default SelectSection;
