import React from 'react';
import logo from './logo.png'; // Update with your logo path


const Footer = () => {
    return (
        <div className="footer">
            <img src={logo} alt="Logo" className="footer-logo" />
        </div>
    );
};

export default Footer;
