//TaskList.js

import React, { useState, useEffect } from 'react';
import { firestore } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

const TasksList = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'tasks'));
                let tasksArray = [];
                querySnapshot.forEach((doc) => {
                    tasksArray.push({ id: doc.id, ...doc.data() });
                });

                // Sort tasks by taskGroup
                tasksArray.sort((a, b) => a.taskGroup.localeCompare(b.taskGroup));

                setTasks(tasksArray);
            } catch (error) {
                console.error('Error fetching tasks: ', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTasks();
    }, []);

    const renderTasks = () => {
        let lastTaskGroup = null;
        return tasks.map((task, index) => {
            const taskElement = (
                <li key={task.id}>
                    {task.taskGroup} - {task.taskShortName} - {task.defaultHours} hours
                    {/* Display other task fields as needed */}
                </li>
            );

            if (task.taskGroup !== lastTaskGroup) {
                lastTaskGroup = task.taskGroup;
                return (
                    <React.Fragment key={index}>
                        {index !== 0 && <br />}
                        {taskElement}
                    </React.Fragment>
                );
            }
            return taskElement;
        });
    };

    if (loading) {
        return <div>Loading tasks...</div>;
    }

    return (
        <div>
            <h2>Tasks List</h2>
            <ul>
                {renderTasks()}
            </ul>
        </div>
    );
};

export default TasksList;
