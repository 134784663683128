import './App.css';


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import EmailForm from './EmailForm';
import TaskCreationForm from './TaskCreationForm';
import TasksList from './TasksList'
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import Navigation from './Navigation'; // Import your Navigation component
import Footer from './Footer';

function App() {
    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Navigation /> {/* Navigation component */}
                    <div className="content"> {/* This div wraps the main content */}
                        <Routes>
                            <Route exact path="/" element={<ProtectedRoute><EmailForm /></ProtectedRoute>} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/create-task" element={<ProtectedRoute><TaskCreationForm /></ProtectedRoute>} />
                            <Route path="/show-tasks" element={<ProtectedRoute><TasksList /></ProtectedRoute>} />
                            {/* Add more routes as needed */}
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </Router>
        </AuthProvider>
    );
}


export default App;